<template>
  <page-header-wrapper content="设置角色的路由权限">
    <a-card class="card" :title="roleInfo.roleName" :bordered="false">
      <template>
        <a-form :form="form" class="form" v-if="isShowForm">
          <!--a-row class="form-row" :gutter="16">
            <a-col :lg="6" :md="12" :sm="24">
              <s-tree
                :dataSource="routeList"
                :openKeys.sync="openKeys"
                >
              </s-tree>
            </a-col>
          </a-row-->
          <a-row class="form-row" :gutter="16" v-if="roleInfo.isBaseRole === 1">
            <a-col :lg="6" :md="12" :sm="24">
              <a-tag color="gold">注：此角色为该站点基础角色，不可编辑路由权限</a-tag>
            </a-col>
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="6" :md="12" :sm="24">
              <a-tree
                v-if="routeList.length > 0"
                :checkable="true"
                :auto-expand-parent="true"
                :default-expand-parent="true"
                :default-expand-all="true"
                :treeData="routeList"
                :checkedKeys="checkedKeys"
                :selectedKeys="checkedKeys.checked"
                @select="onSelect"
                @check="onCheck"
              >
              </a-tree>
            </a-col>
          </a-row>
          <a-form-item>
            <span class="table-page-search-submitButtons">
              <a-button
                type="primary"
                @click="gotoBack"
                style="margin-right: 10px;"
              >
                返回
              </a-button>
              <a-button type="primary" @click="setRolePowerApi" :disabled="(roleInfo.isBaseRole === 1) ? true : false">设置</a-button>
            </span>
          </a-form-item>
        </a-form>
      </template>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { roleUserInfo, setRolePower } from '@/api/role'
import { routeList } from '@/api/route'

export default {
  name: 'PowerInfo',
  components: {
    STree
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addpower' }),
      loading: false,
      memberLoading: false,
      roleParam: {
        roleId: 0
      },
      roleInfo: {},
      routeList: [],
      openKeys: ['userAll'],
      checkedKeys: {
        checked: [],
        halfChecked: []
      },
      defaultExpandedKeys: [],
      rolePowerParams: {
        roleId: 0,
        routePower: [],
        modelPower: []
      },
      defaultExpandAll: true,
      isShowForm: true
    }
  },
  created () {
    this.isShowForm = true
    this.routeListApi()
    if (this.$route.query.roleId !== 0 && this.$route.query.roleId !== undefined && this.$route.query.roleId !== '') {
      this.roleParam.roleId = parseInt(this.$route.query.roleId)
      this.roleInfoApi()
    }
  },
  watch: {
    $route (val) {
      console.log(val.name)
      if (val.name === 'PowerInfo') {
        this.isShowForm = false
        this.$nextTick(function () {
          this.isShowForm = true
        })
        this.routeListApi()
        if (this.$route.query.roleId !== 0 && this.$route.query.roleId !== undefined && this.$route.query.roleId !== '') {
          this.roleParam.roleId = parseInt(this.$route.query.roleId)
          this.roleInfoApi()
        }
      }
    }
  },
  methods: {
    gotoBack () {
      this.$router.push({ path: '/role/role-list' })
    },
    roleInfoApi () {
      const _this = this
      roleUserInfo(_this.roleParam).then((res) => {
        if (res.errorCode === 0) {
          _this.roleInfo = res.result
          _this.checkedKeys.checked = _this.roleInfo.keyList.checked
          _this.checkedKeys.halfChecked = _this.roleInfo.keyList.halfChecked
          console.log('roleInfoApi', _this.roleInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    routeListApi () {
      const _this = this
      routeList().then((res) => {
        if (res.errorCode === 0) {
          _this.defaultExpandedKeys = res.result.defaultExpandedKeys
          _this.routeList = res.result.list
          _this.defaultExpandAll = false
          // _this.checkedKeys = res.result.baseKey
          console.log('routeListApi', _this.routeList)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    onSelect (selectedKeys, info) {
      console.log('selected', selectedKeys, info)
    },
    onCheck (checkedKeys, info) {
      const _this = this
      _this.checkedKeys.checked = checkedKeys
      _this.checkedKeys.halfChecked = info.halfCheckedKeys
      console.log('onCheck', _this.checkedKeys)
    },
    setRolePowerApi () {
      const _this = this
      _this.rolePowerParams.roleId = _this.roleParam.roleId
      _this.rolePowerParams.routePower = _this.checkedKeys
      setRolePower(_this.rolePowerParams).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success(res.errorMsg)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .card{
    margin-bottom: 24px;
  }
  .popover-wrapper {
    /deep/ .antd-pro-pages-forms-style-errorPopover .ant-popover-inner-content {
      min-width: 256px;
      max-height: 290px;
      padding: 0;
      overflow: auto;
    }
  }
  .antd-pro-pages-forms-style-errorIcon {
    user-select: none;
    margin-right: 24px;
    color: #f5222d;
    cursor: pointer;
    i {
          margin-right: 4px;
    }
  }
  .antd-pro-pages-forms-style-errorListItem {
    padding: 8px 16px;
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background: #e6f7ff;
    }
    .antd-pro-pages-forms-style-errorIcon {
      float: left;
      margin-top: 4px;
      margin-right: 12px;
      padding-bottom: 22px;
      color: #f5222d;
    }
    .antd-pro-pages-forms-style-errorField {
      margin-top: 2px;
      color: rgba(0,0,0,.45);
      font-size: 12px;
    }
  }
</style>
