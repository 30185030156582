import request from '@/utils/request'
const baseURL = '/baseapi'
const routeApi = {
  RouteList: '/admin/route/list'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function routeList () {
  return request({
    baseURL: baseURL,
    url: routeApi.RouteList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
